<template>
  <div class="result-container">
      <div>
          <Loader class="sold-loader" v-if="!count" text="Загружаем информацию по продажам..." />
      </div>
      <div v-if="count">
          <span class="curency text">{{count}}</span><span class="undertext text">руб.</span>
          <div class="undertext text">было собрано</div>
      </div>
      <!-- <div v-if="count" class="middle">
          <span class="curency text">{{books}}</span><span class="undertext text"></span>
          <div class="undertext text">Осталось всего книг</div>
      </div> -->
      <div v-if="count" class="books-sold">
          <span class="curency text">{{soldBooks}}</span><span class="undertext text"></span>
          <div class="undertext text">продано книг</div>
      </div>
  </div>
</template>

<script>
// ЗАКРЫТА
import Loader from "../components/Loader";
export default {
    components: {
        Loader
    },
    props: {
        count: Number,
        books: Number,
        soldBooks: Number
    }

}
</script>

<style scoped>
.result-container {
    position: relative;
    background-color: #F8F6F4;
    height: 205px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.sold-loader {
    top: 25%;
}

.result-container div {
    padding: 0px 25px;
}

.middle {
    border-right: 1px solid rgba(163, 137, 112, 0.6);
    border-left: 1px solid rgba(163, 137, 112, 0.6);
}

.books-sold {
    border-left: 1px solid rgba(163, 137, 112, 0.6);
}

.text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
    color: #282828;
}
.undertext {
    font-size: 18px;
}
.curency {
    font-size: 60px;
    line-height: 102px;
    letter-spacing: 0px;
}

@media only screen and (max-width: 670px) {
    .result-container {
        flex-direction: column;
        height: 100%;
        padding: 30px 0;
        min-height: 200px;
    }

    .middle {
        border-right: 0;
        border-left: 0;
    }
    .books-sold {
        border-left: 0;
    }
}
</style>