import firebase from "firebase/app";
import { v4 as generateUUID } from "uuid";

export default {
    state: {
        userData: "",
        userBooks: [],
        error: ""
    },

    mutations: {
        SET_USER_DATA(state, payload) {
            state.userData = payload;
        },
        SET_ERROR(state, payload) {
            state.error = payload;
        },
        SET_USER_BOOKS(state, payload) {
            state.userBooks = payload;
        }
    },

    actions: {
        setUserData({ commit, getters }) {
            const id = getters.getUserId;
            firebase.firestore().collection("users").doc(id).get().then((doc) => {
                    if (doc.exists) {
                        commit("SET_USER_DATA", doc.data());
                    } else {
                        console.log("No such document!");
                    }
            });
        },
        async addNewBook({ getters, state }, payload) {
            const images = payload.images;
            const imageLinks = [];
            const storage = firebase.storage().ref();
            if(images.length) {
                for(let i = 0; i < images.length; i++) {
                    const id = generateUUID();
                    const fileRef = storage.child(id);
                    await fileRef.put(images[i]);
                    const url = await fileRef.getDownloadURL();
                    imageLinks.push(url);
                }
            }
            payload.images = imageLinks;
            const bookId = generateUUID();
            const userData = state.userData;
            firebase.firestore().collection("books").doc(bookId).set({
                ...payload,
                id: bookId,
                city: userData.city,
                userId: getters.getUserId,
                processed: false,
                bought: false
            }).then(() => {
                console.log("Book added");
            }).catch((err) => {
                console.log(err);
            });
        },
        async bumpUserBooks({ getters, commit }) {
            const id = getters.getUserId;
            const books = [];
            const booksCollection = await firebase.firestore().collection("books").get();
            booksCollection.forEach((doc) => {
                const data = doc.data();
                if(data.userId === id) {
                    books.push(data);
                }
            });

            commit("SET_USER_BOOKS", books);
        }
    },

    getters: {
        getUserData: state => state.userData,
        getUserBooks: state => state.userBooks
    },
}
