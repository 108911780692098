<template>
  <input type="email" :value="modelValue" @input="updateValue($event.target.value)" :class="{ 'is-invalid': isInvalid || !isValid }" class="form-control" id="login">
</template>

<script>
import { ref } from "vue";
export default {
  props: {
      modelValue: String,
      isInValidValue: {
          type: Boolean
      },
      isValid: {
          type: Boolean,
          default: ()=>{
              return true;
          }
      }
  },
  emits: ['update:modelValue', 'update:isValid'],
  setup(props, { emit }) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const email = ref("");
    const isInvalid = ref(false);
    const isValidEmail = (value) => {
      return re.test(String(value).toLowerCase());
    };
    const updateValue = (value) => {
      isInvalid.value = isValidEmail(value) ? false : true;
      emit("update:modelValue", value);
      emit("update:isValid", !isInvalid.value);
    };
    return {
      isInvalid,
      updateValue,
      email
    };
  }
}
</script>

<style>

</style>