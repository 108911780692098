<template>
    <teleport to="body">
        <div class="alert alert-primary" v-if="visible" role="alert">
            <slot />
        </div>
    </teleport>
</template>

<script>
import { watch } from "vue";
export default {
    props: {
        time: {
            type: Number,
            default: 1000
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        watch(() => props.visible, (value) => {
            setTimeout(() => {
                emit('update:visible', value);
            }, props.time);
        });
    }

}
</script>

<style scoped>
.alert-primary {
    position: absolute;
    width: 80%;
    text-align: center;
    top: 20%;
    transform: translate(13%, 0);
}
</style>