<template>
  <div class="toolbar">
      <div class="right-side">
          <Search></Search>
      </div>
  </div>
</template>

<script>

import Search from "./Search";
export default {
    components: {
        Search
    }
}
</script>

<style scoped>
    .right-side {
        max-width: 500px;
        width: 100%;
        margin-left: 15px;
    }
    .toolbar {
        display: flex;
        align-items: center;
        margin: 0 10% 80px 10%;
    }
    @media only screen and (max-width: 840px) {
    .left-side {
      margin: 0 auto;
    }
}
</style>