<template>
  <div class="rules">
      <h2>Принцип распродажи</h2>
      <div class="rules-description">
          <div>
            <img class="animal" src="../assets/dog.jpg" />
          </div>
          <div class="text">
              <div>
                <!-- <p>Чтобы поучаствовать в проекте вы можете <b class="link"><router-link to="/sponsors">отдать книги безвозмездно</router-link></b> или <b class="link"><router-link to="/books">приобрести книги</router-link></b> за ту стоимость, которые Вы посчитаете уместной.</p> -->
                <p>Чтобы поучаствовать в проекте вы можете отдать книги безвозмездно или приобрести книги за ту стоимость, которые Вы посчитаете уместной.</p>
                <p>Все деньги, полученные с продаж, переводим на счёт двух приютов для бездомных животных <b class="link" @click="onClick('https://vk.com/saved_to_live')">"Спасённые, чтобы жить"</b> и <b class="link" @click="onClick('https://vk.com/club189884687')">"Зверополис"</b>.</p>
                <p>
                    <BaseButton class="btn btn-primary" type="button" @click="opened = !opened">Как купить книгу</BaseButton>
                    </p>
                    <div class="row">
                        <div class="col">
                            <div class="collapsing" :class="{ 'show-rule': opened }">
                                <div class="card card-body">
                                    <p>Для того чтобы купить книги, вам необходимо сделать следующие шаги:</p>
                                    <div class="poins-rule">
                                        <p> 1. Добавить книги в корзину</p>
                                        <p> 2. Заполнить форму заказа и отправить заявку</p>
                                        <p> 3. Вам на почту придет сообщение с данными заказа и реквизитами для оплаты</p>
                                        <p> 4. Оплатить заказ (<b>не забудьте указать номер вашего заказа в сообщении при оплате</b>)</p>
                                        <br>
                                        <p><b>Просим обратить Ваше внимание</b>, что часть книг находится в разных городах, из-за чего необходимо учитывать стоимость доставки, которая дополнительно оплачивается Вами</p>
                                        <p>Если вы проживаете в г. Рязани, вы сможете забрать книги в пункте выдачи по адресу ул. Есенина, 9, 5ый этаж. Вам придет уведомление на почту о готовности заказа.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
// ЗАКРЫТА
import BaseButton from "./BaseButton";
export default {
    components: {
        BaseButton
    },
    data() {
        return {
            opened: false
        };
    },
    methods: {
        onClick(link) {
            window.open(link);
        }
    }
}
</script>

<style scoped>
.animal {
    width: 512px;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.collapsing.show-rule {
    height: 100%;
}

.link {
    cursor: pointer;
    color: #A38970;
}

.link a {
    cursor: pointer;
    color: #A38970;
}

.rules {
    margin-left: 10%;
}
.rules-description {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
h2 {
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 42px;
}
.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
    background-color: #A38970 !important;
    color: #fff;
}
.text {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: left;;
    margin-left: 80px;
    margin-top: 20px;
}

.text div {
    max-width: 655px;
}
.btn {
    width: 240px;
    height: 56px;
    height: 56px;
    width: 240px;
    left: 692px;
    top: 1332px;
    border-radius: 3px;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin-top: 30px;

}

@media only screen and (max-width: 1330px) {
    p {
        width: 550px;
    }
}
@media only screen and (max-width: 1400px) {
    .poins-rule p{
        text-align: left;
        width: 80%;
    }
    p {
        max-width: 610px;
        text-align: center;
        width: 100%
    }
    h2 {
        margin-left: 0;
        text-align: center;
    }
    .rules {
        margin-left: 0;
    }
    .rules-description {
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;
    }
    .text {
        margin-left: 0;
        text-align: center;
    }
    .text div {
        margin: 0 auto;
    }

}
@media only screen and (max-width: 840px) {
    .animal {
        width: 80%;
    }

    .poins-rule p {
        width: 100%;
    }

    .card {
        margin: 0;
        padding: 0;
    }
}
@media only screen and (max-width: 430px) {
    .text {
        padding: 0 10px;
    }

    p {
        margin: 0 0 20px;
    }
    .text div {
        margin: 0 10px;
    }
}
</style>