import firebase from "firebase/app";

export default {
    state: {
        userId: "",
        error: ""
    },

    mutations: {
        SET_USER_ID(state, payload) {
            state.userId = payload;
        },
        SET_ERROR(state, payload) {
            state.error = payload;
        }
    },

    actions: {
        signUpUser({ commit }, payload) {
            return new Promise((resolve, reject) => {
                firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
                    .then(response => {
                        commit("SET_USER_ID", response.user.uid);
                        firebase.firestore().collection("users").doc(response.user.uid).set({
                            email: payload.email,
                            name: payload.name,
                            surname: payload.surname,
                            phone: payload.phone,
                            city: payload.city
                        }).then(() => {
                            resolve(response.user);
                        });
                    })
                    .catch(error => {
                        commit("SET_ERROR", error.message);
                        reject(error.message);
                    });
            });
        },
        signInUser({ commit }, payload) {
            return new Promise((resolve, reject) => {
                firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
                    .then(response => {
                        commit("SET_USER_ID", response.user.uid);
                        resolve(response.user.id)
                    })
                    .catch((error) => {
                        commit("SET_ERROR", error.message);
                        reject(error.message);
                });
            });
        },
        signOutAction({ commit }) {
            return new Promise((resolve, reject) => {
                firebase.auth().signOut()
                .then(() => {
                    commit("SET_USER_ID", "");
                    commit("SET_USER_DATA", null);
                    resolve("Success");
                })
                .catch(error => {
                    commit("SET_ERROR", error.message);
                    reject(error);
                });
            });
        },
        fetchUserInfo({ commit }) {
            const user = firebase.auth().currentUser;
            if(user) {
                commit("SET_USER_ID", user.uid);
            }

        }
    },

    getters: {
        getUserId: state => state.userId,
        getError: state => state.error
    },
}
