<template>
    <div class="badge-container" v-if="value">
        <span class="badge badge-pill">
            <div class="badge-content">
                {{value}}
            </div>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number
        }
    }

}
</script>

<style>
.badge {
    border: 1.5px solid black;
    background-color: #fff;
}
.badge-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0%, 0%);
}

</style>