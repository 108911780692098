<template>
    <ModalWindow />
    <Description image="page1.jpeg">
        <template #text>
            <div class="desc-date">{{date}}</div>
            <h2 class="desc-title">Выбор книги начинается здесь</h2>
            <p class="desc-main books-main-desc">Понравившуюся книгу вы можете выкупить и забрать её в пункте выдачи продаж. Также вы можете отдать книги безвозмездно.</p>
        </template>
    </Description>
    <!-- <Result :count="booksRef.count" :books="booksRef.allBooks" :soldBooks="booksRef.soldBooksCount" /> -->
    <BooksList v-model:books="books" :exclusive="excl"/>
    <BaseButton v-if="lastDoc !== undefined && lastDoc !== null"  @click="updateBooks">Загрузить еще</BaseButton>
    <div v-else-if="lastDoc !== null" class="all-books">Все книги уже показаны)</div>
</template>

<script>
import BooksList from "../components/BooksList";
import Description from "../components/Description";
import BaseButton from "../components/BaseButton";
import ModalWindow from "../components/ModalBook";

import { ref, onMounted, onUnmounted, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { date } from '../common/constants';

export default {
    components: {
        Description,
        BooksList,
        BaseButton,
        // Pager,
        // Result,
        ModalWindow
    },
     setup() {
        const store = useStore();
        const router = useRoute();

        const bookData = ref({});
        const excl = ref(false);
        const books = computed(() => {
            return store.getters.books;
        });
        const lastDoc = computed(() => {
            return store.getters.lastDoc;
        });

        if(router.query && router.query.exlusive) {
            excl.value = true;
            store.commit("UPDATE_EXCLUSIVE_FILTER", true);
        } else {
            store.commit("UPDATE_EXCLUSIVE_FILTER", false);
        }

        const updateBooks = () => {
            store.dispatch("getBooks");
        };

        onUnmounted(() => {
            store.commit('UPDATE_LAST_DOC', null);
            store.commit('UPDATE_BOOKS', null);
        })

        onMounted(() => {
            // store.dispatch("getAllBooks").then(() => {
                store.dispatch("getBooks");
            // });
        });

        return {
            updateBooks,
            books,
            bookData,
            date,
            lastDoc,
            excl
        };

    }
}
</script>

<style>
.books-main-desc {
    margin-bottom: 80px;
}
.all-books {
    color: #A38970;
}
</style>