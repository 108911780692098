<template>
  <div class="info-container">
        <form class="form-horizontal" v-if="userData">
            <div class="form-group">
                <label class="col-sm-12 control-label" for="name">Имя:</label>
                <div class="col-sm-12">
                    <SimpleInput disabled :modelValue="userData.name" id="name" />
                </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-12 control-label" for="surname">Фамилия:</label>
                            <div class="col-sm-12">
                                <SimpleInput disabled :modelValue="userData.surname" id="surname" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-12 control-label" for="email">Email:</label>
                            <div class="col-sm-12">
                                <Email disabled :modelValue="userData.email" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-12 control-label" for="phone">Номер телефона:</label>
                            <div class="col-sm-12">
                                <SimpleInput disabled type="tel" :modelValue="userData.phone" id="phone"/>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-12 control-label" for="city">Город:</label>
                            <div class="col-sm-12">
                                <SimpleInput disabled :modelValue="userData.city" id="city" />
                            </div>
                        </div>
                    </form>
      </div>
</template>

<script>
import SimpleInput from "../components/text-input/Simple";
import Email from "../components/text-input/Email";
export default {
    components: {
        SimpleInput,
        Email
    },
    props: {
        userData: Object
    }
}
</script>

<style>
.info-container .form-control {
    color: #2B2B2B;
}
</style>