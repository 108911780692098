<template>
  <div class="filter">
      <div class="filter-button" @click="openFilter($event)">
          <i class="fas fa-sliders-h fa-lg"></i>
      </div>
      <div class="filter-content" v-if="opened" ref="popup">
        <div class="filter-title">
          <i class="custom-icon fas fa-times fa-lg close-button" @click="close()"></i>
        </div>
        <div class="filter-block">
          <div class="form-group">
              <label class="col-sm-12 control-label" for="cover">Город</label>
                  <div class="col-sm-12">
                    <SelectBox :value="checkedCity" :items=[...booksData.cities] v-model:checkedItem="checkedCity" />
                  </div>
          </div>
        </div>
        <div class="filter-block">
          <div class="form-group">
              <label class="col-sm-12 control-label" for="cover">Язык</label>
              <div class="col-sm-12">
                <SelectBox :value="checkedLang" :items=[...booksData.languages] v-model:checkedItem="checkedLang" />
              </div>
          </div>
        </div>
        <div class="filter-block">
          <div class="form-group">
              <label class="col-sm-12 control-label" for="cover">Автор</label>
              <div class="col-sm-12">
                <SelectBox :value="checkedNames" :items=[...booksData.authors] v-model:checkedItem="checkedNames" />
              </div>
          </div>
        </div>
        <div class="filter-block">
          <div class="form-group">
              <label class="col-sm-12 control-label" for="cover">Жанр</label>
              <div class="col-sm-12">
                <SelectBox :value="checkedGenre" :items=[...booksData.genres] v-model:checkedItem="checkedGenre" />
              </div>
          </div>
        </div>
        <div class="filter-block">
          <div class="form-group">
              <div class="col-sm-12">
                <CheckBox v-model="checkedExclusive" :isValid="true" :isRequired="false" />
                <label class="form-check-label" for="checkbox">Показать эксклюзивы</label>
              </div>
          </div>
        </div>
        <div class="actions">
          <button  type="button" class="btn btn-secondary filter-btn apply-filter" @click="applyFilter">Применить</button>
          <button  type="button" class="btn btn-secondary filter-btn reset-filter" @click="resetFilter">Сбросить</button>
        </div>
      </div>
  </div>
</template>

<script>
// import List from "./List";
import SelectBox from "./SelectBox.vue";
import CheckBox from "./Checkbox.vue";
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    SelectBox,
    CheckBox
    // List
  },
  props: {
    exclusive: Boolean
  },
  setup(props) {
    /* eslint-disable */
    const store = useStore();
    const popup = ref(null);
    const opened = ref(false);
    const skipClose = ref(true);
    const isButton = ref(false);
    const cities = new Set([""]);
    const languages = new Set([""]);
    const genres = new Set([""]);
    const exclusive = ref(props.exclusive || false);
    const authors = new Set([""]);
    const checkedCity = ref("");
    const checkedExclusive = ref(props.exclusive || false);
    const checkedLang = ref("");
    const checkedGenre = ref("");
    const checkedNames = ref("");
    const close = () => {
        skipClose.value = true;
        isButton.value = true;
        opened.value = false;
    };

    const booksData = computed(() => {
          const data = store.getters.allBooks;
          if(data && data.length) {
            data.forEach((b) => {
                cities.add(b.city);
                languages.add(b.launguage);
                authors.add(b.author);
                genres.add(b.genre);
            });
          }
          return {
            cities,
            languages,
            authors,
            genres
          }
    });

    const clickOutside = (e) => {
        if(!popup.value) {
            return;
        }
        if(isButton.value) {
            isButton.value = false;
            return;
        }

        if(skipClose.value) {
            skipClose.value = false;
            return;
        }
        if(popup.value !== e.target && !popup.value.contains(e.target)) {
            skipClose.value = true;
            opened.value = false;
        }
    }

    const applyFilter = () => {
      store.commit("UPDATE_CITY_FILTER", checkedCity.value);
      store.commit("UPDATE_LANG_FILTER", checkedLang.value);
      store.commit("UPDATE_AUTHOR_FILTER", checkedNames.value);
      store.commit("UPDATE_GENRE_FILTER", checkedGenre.value);
      store.commit("UPDATE_EXCLUSIVE_FILTER", checkedExclusive.value);
      store.commit('UPDATE_BOOKS', null);
      store.commit('UPDATE_LAST_DOC', null);
      store.dispatch("getBooks");
      close();

    };

    const resetFilter = () => {
      checkedNames.value = "";
      checkedCity.value = "";
      checkedLang.value = "";
      checkedExclusive.value = false;
      store.commit("UPDATE_CITY_FILTER", "");
      store.commit("UPDATE_LANG_FILTER", "");
      store.commit("UPDATE_AUTHOR_FILTER", "");
      store.commit("UPDATE_GENRE_FILTER", "");
      store.commit("UPDATE_EXCLUSIVE_FILTER", false);
      store.commit('UPDATE_BOOKS', null);
      store.commit('UPDATE_LAST_DOC', null)
      store.dispatch("getBooks");
      close();
    };

    const openFilter = () => {
      opened.value = true;
    };

    onMounted(() => {
        window.addEventListener("click", clickOutside);
    });
    onUnmounted(() => {
        window.removeEventListener("click", clickOutside);
        resetFilter()
    });
    return {
        applyFilter,
        checkedNames,
        checkedCity,
        checkedLang,
        checkedGenre,
        checkedExclusive,
        close,
        popup,
        opened,
        openFilter,
        booksData,
        resetFilter
    }

  }
}
</script>

<style scoped>
  .filter-block {
      margin: 10px 0;
  }

  .filter-title {
      visibility: hidden;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group .form-check-input {
    margin-left: 0;
  }

  .form-group .form-check-label {
    margin-left: 20px;
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .filter-button{
    cursor: pointer;
    border: 1px solid #A38970;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    color: #A38970;
    width: 36px;
  }
  .apply-filter {
    width: 150px;
    height: 50px;
    margin: 10px 10px;

    background-color: #A38970 !important;
    color: #fff;
  }

  .reset-filter {
    width: 150px;
    height: 50px;
    margin: 10px 0;

    background-color: #fff !important;
    color: #2c3e50;
  }
  .filter-name {
    text-align: left;
    margin-left: 5%;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
  }

  .filter {
    position: relative;
    display: inline-block;
  }
  .btn {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {

}

.filter-content {
  display: block;
  position: absolute;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  min-width: 500px;
  overflow-y: auto;
  /* max-height: 500px; */
  -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
  box-shadow: 0 3px 9px rgba(0,0,0,.5);
  z-index: 1;
}
@media only screen and (max-width: 840px) {
    .filter-title {
      visibility: visible;
      display: flex;
      justify-content: flex-end;
      margin: 15px;
    }
    .filter-content {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      overflow-y: auto;
      height: 100%;
      min-width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
}
</style>