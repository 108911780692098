import { createRouter, createWebHashHistory } from "vue-router";
import Main from "./views/Main";
import Books from "./views/Books";
// import Order from "./views/Order";
// import Funder from "./views/Funder";
// import Registration from "./views/Registration";
// import Restore from "./views/Restore";
import Account from "./views/Account";
import Info from "./views/Info";
import FunderBooks from "./views/FunderBooks";
import NewBook from "./views/parts/NewBook";
import Contacts from "./views/Contacts";
import Login from "./components/auth/Login";
// import Shop from "./views/Shop"
//ЗАКРЫТА
const routes = [
  { path: '/', component: Main },
  { path: '/main', component: Main },
  // { path: '/books', component: Books },
  { path: '/secretbooks', component: Books },
  // { path: '/order', component: Order },
  // { path: '/sponsors', component: Funder },
  // { path: '/registration', component: Registration },
  { path: '/login', component: Login },
  // { path: '/restore', component: Restore },
  { path: '/contacts', component: Contacts },
  // { path: '/shop', component: Shop },
  { path: '/account/:id', component: Account, children: [{
    path: 'info',
    component: Info
  }, {
    path: 'books',
    component: FunderBooks
  },
  {
    path: 'new-book',
    component: NewBook
  }
] }
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes
})

