<template>
  <input type="text" :value="modelValue" @input="updateValue($event.target.value)" :class="{ 'is-invalid': isInvalid || !isValid }" class="form-control">
</template>

<script>
import { ref } from "vue";
export default {
  props: {
      modelValue: String,
      isValid: {
          type: Boolean,
          default: ()=>{
              return true;
          }
      }
  },
  emits: ['update:modelValue', 'update:isValid'],
  setup(props, { emit }) {
    const isInvalid = ref(false);
    const updateValue = (v) => {
      isInvalid.value = v === "" ? true : false;
      emit('update:modelValue', v);
      emit("update:isValid", !isInvalid.value);
    };
    return {
      isInvalid,
      updateValue
    };
  }
}
</script>

<style>

</style>