import firebase from "firebase/app";

export default {
    state: {
        books: [],
        allBooks: [],
        lastDoc: null,
        langFilter: "",
        cityFilter: "",
        authorFilter: "",
        genreFilter: "",
        exclusiveFilter: false,
        bookName: "",
        count: 0,
        sold: 0,
        price: 0,
        freeBooks: 0
    },

    mutations: {
        UPDATE_BOOKS(state, books) {
            if(books === null) {
                state.books = [];
            } else {
                state.books.push(...books);
            }
        },
        UPDATE_COUNT(state, count) {
            state.count = count;
        },
        UPDATE_ALL_BOOKS(state, books) {
            state.allBooks = books;
        },
        UPDATE_LANG_FILTER(state, lang) {
            state.langFilter = lang;
        },
        UPDATE_CITY_FILTER(state, cities) {
            state.cityFilter = cities;
        },
        UPDATE_AUTHOR_FILTER(state, authors) {
            state.authorFilter = authors;
        },
        UPDATE_EXCLUSIVE_FILTER(state, exclusive) {
            state.exclusiveFilter = exclusive;
        },
        UPDATE_GENRE_FILTER(state, genres) {
            state.genreFilter = genres;
        },
        UPDATE_LAST_DOC(state, page) {
            state.lastDoc = page;
        },
        UPDATE_BOOK_NAME(state, name) {
            state.bookName = name;
        },
        UPDATE_SOLD(state, sold) {
            state.sold += sold;
        },
        UPDATE_PRICE(state, sold) {
            state.price += sold;
        },
        UPDATE_FREE_BOOKS(state, books) {
            state.freeBooks += books;
        }
    },

    actions: {
        async getAllBooks({ commit }) {
            const booksCollection = await (await firebase.firestore().collection("books").get());
            const books = [];
            booksCollection.forEach((doc) => {
                const data = doc.data();
                if(!data.bought && data.processed) {
                    books.push(data);
                    commit('UPDATE_FREE_BOOKS', 1);
                } else if(data.bought) {
                    commit('UPDATE_SOLD', 1);
                }
                if(data.price && data.bought) {
                    commit('UPDATE_PRICE', Number.parseInt(data.price));
                }
            });
            commit('UPDATE_ALL_BOOKS', books);
        },
        async getBooks({ commit, state }) {
            if(state.lastDoc === undefined) {
                return;
            }
            let booksCollection = firebase.firestore().collection("books");
            const city = state.cityFilter;
            const lang = state.langFilter;
            const author = state.authorFilter;
            const genre = state.genreFilter;
            const exclusive = state.exclusiveFilter;
            const name = state.bookName;
            let db = firebase
            .firestore();
            db = db.collection("books");
            db = db.where('bought', '==', false);
            db = db.where('processed', "==", true);
            if(city) {
                db = db.where('city', "==", city);
            }
            if(lang) {
                db = db.where('launguage', "==", lang);
            }
            if(author) {
                db = db.where('author', "==", author);
            }
            if(genre) {
                db = db.where('genre', "==", genre);
            }
            if(exclusive) {
                db = db.where('exclusive', "==", exclusive);
            }
            if(name) {
                // db = db.where('name', "==", name);
                db = db.where('name', '>=', name).where('name', '<', name + 'z');

            } else {
                db = db.orderBy("name");
            }

            if(state.lastDoc === null) {
                booksCollection = await db
                    .limit(12)
                    .get();
            } else {
                booksCollection = await db
                    .startAfter(state.lastDoc)
                    .limit(12)
                    .get();
            }

            commit('UPDATE_LAST_DOC', booksCollection.docs[booksCollection.docs.length - 1]);
            const books = [];
            booksCollection.forEach((doc) => {
                const data = doc.data()
                books.push(data);
            });
            commit('UPDATE_BOOKS', books);
        },

    },

    getters: {
        books: state => state.books,
        allBooks: state => state.allBooks,
        freeBooks: state => state.freeBooks,
        soldBooks: state => state.sold,
        price: state => state.price,
        booksById: (state) => (id) => {
            return state.books.find(book => book.id === id);
        },
        count: state => state.count,
        lastDoc: state => state.lastDoc
    },
}
