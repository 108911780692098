<template>
    <div>
        <Loader class="loader" v-if="loading" />
        <Notify v-model:visible="showNotify">Книга добавлена!</Notify>
        <form class="form-horizontal" @submit.prevent="onSubmit" :class="{ disabled: loading }">
            <div class="form-content">
                <div class="left-side">
                    <div class="form-group">
                        <label class="col-sm-12 control-label" for="name">Название</label>
                        <div class="col-sm-12">
                            <SimpleInput v-model="data.name" id="name" v-model:isValid="validate.isValidName" />
                            <div class="invalid-feedback">
                                    Заполните поле 'Название'
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-12 control-label" for="author">Автор</label>
                        <div class="col-sm-12">
                            <SimpleInput v-model="data.author" id="author" v-model:isValid="validate.isValidAuthor" />
                            <div class="invalid-feedback">
                                    Заполните поле 'Автор'
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-12 control-label" for="cover">Обложка</label>
                        <div class="col-sm-12">
                            <select id="cover" v-model="data.cover" class="custom-select form-control" aria-label="Default select example">
                                <option selected value="Твердая">Твердая</option>
                                <option value="Мягкая">Мягкая</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-12 control-label" for="cover">Жанр</label>
                        <div class="col-sm-12">
                            <select id="cover" v-model="data.genre" class="custom-select form-control" aria-label="Default select example">
                                <option v-for="(genre, index) in genres" :key="index" :value="genre">{{genre}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-12 control-label" for="launguage">Язык</label>
                        <div class="col-sm-12">
                            <select id="cover" v-model="data.launguage" class="custom-select form-control" aria-label="Default select example">
                                <option v-for="(launguage, index) in launguages" :key="index" :value="launguage">{{launguage}}</option>
                            </select>
                            <!-- <SimpleInput v-model="data.launguage" id="launguage" v-model:isValid="validate.isValidLaunguage" /> -->
                            <div class="invalid-feedback">
                                    Заполните поле 'Язык'
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-side">
                    <div class="form-group drop-zone-form">
                        <label class="col-sm-12 control-label" for="launguage">Загрузите фотографии книги</label>
                        <div class="col-sm-12 drop-zone form-control">
                            <div class="upload-info">
                                <img class="upload-image" src="../../assets/upload.svg" />
                                <p v-if="!data.images.length">Пожалуйста добавьте фото обложки, фото страницы, титульный лист</p>
                                <ul>
                                    <li v-for="(item, index) in data.images" :key="index">
                                        {{item.name}}
                                    </li>
                                </ul>
                            </div>
                            <input ref="fileUploader" accept="image/jpeg,image/png,image/gif" :class="{ 'is-invalid': !validate.isValidFile }" @change="onChange" class="form-control file-uploader" type="file" multiple />
                            <div class="invalid-feedback">
                                Добвьте фотографии книги
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="action">
                <div class="action-container">
                    <div>
                        <BaseButton type="submit" class="btn btn-default">Добавить</BaseButton>
                    </div>
                </div>
                <div class="action-link">
                    <!-- <div class="link" >Посмотреть пример<i class="arrow fas fa-long-arrow-alt-right"></i></div> -->
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import SimpleInput from "../../components/text-input/Simple";
import BaseButton from "../../components/BaseButton";
import Notify from "../../components/common/Notify";
import Loader from "../../components/Loader";
import { reactive, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Compressor from 'compressorjs';

 var isBlob = function (value) {
    if (typeof Blob === 'undefined') {
      return false;
    }

    return value instanceof Blob || Object.prototype.toString.call(value) === '[object Blob]';
};

export default {
    components: {
        SimpleInput,
        BaseButton,
        Notify,
        Loader
    },
    setup() {
        const submitted = ref(false);
        const loading = ref(false);
        const showNotify = ref(false);
        const fileUploader = ref(null);
        const store = useStore();
        const router = useRouter();
        const uid = computed(() => store.getters.getUserId);

        const launguages = [
            "Русский",
            "Английский",
            "Немецкий",
            "Испанский",
            "Итальянский",
            "Французский",
            "Китайский",
            "Японский",
        ].sort();
        launguages.push("Другой");
        const genres = [
            "Детская литература",
            "Психология",
            "Образование",
            "Бизнес. Экономика",
            "Русская классика",
            "Здоровье",
            "Детектив. Боевик. Триллер",
            "Фантастика. Фэнтези",
            "Романтика",
            "Поэзия",
            "Биографии. Мемуары",
            "Исторический роман",
            "Приключения",
            "Комиксы"
        ].sort();
        genres.push("Другой");

        const data = reactive({
            name: "",
            author: "",
            cover: "Твердая",
            launguage: "Русский",
            images: [],
            genre: "",
            exclusive: false,
            price: 0
        });

        const validate = reactive({
            isValidName: true,
            isValidAuthor: true,
            isValidLaunguage: true,
            isValidFile: true
        });

        const isValidData = () => {
            return Object.keys(validate).every((i) => {
                return validate[i];
            })
        };

        const isValidImages = () => {
            const types = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
            const files = fileUploader.value.files;
            let isValid = false;
            files.forEach((f) => {
                isValid = types.some((t) => t === f.type)
            });
            return isValid;
        };

        const onChange = async () => {
            const files = fileUploader.value.files;
            if(files && files.length && isValidImages()) {
                for(let file in files) {
                    const value = files[file];
                    if(value &&  typeof value !== 'string' && typeof value !== 'number' && isBlob(value)) {
                        const res = await new Promise((resolve) => {
                            new Compressor(value, {
                                success: (result) => {
                                    resolve(result);
                                },
                                checkOrientation: false,
                                quality: 0.4
                            })
                        })
                        data.images.push(res);
                    }
                }
                validate.isValidFile = true;
            }
        };

        const onSubmit = () => {
            if(!submitted.value) {
                validate.isValidName = data.name === "" ? false : true;
                validate.isValidAuthor = data.author === "" ? false : true;
                validate.isValidLaunguage = data.launguage === "" ? false : true;
            }
            validate.isValidFile = !data.images.length ? false : true;

            submitted.value = true;

            if(isValidData()) {
                loading.value = true;
                store.dispatch("addNewBook", data).then(() => {
                    loading.value = false;
                    showNotify.value = true;
                    setTimeout(() => {
                        showNotify.value = false;
                        router.push({ path: `/account/${uid.value}/books`});
                    }, 1500);

                }).catch((err) => {
                    loading.value = false;
                    console.log(err)
                });
            }
        };

        return {
            data,
            validate,
            loading,
            fileUploader,
            showNotify,
            launguages,
            genres,
            onChange,
            onSubmit
        };
    }
}
</script>

<style scoped>
    ul {
        padding-inline-start: 0;
    }

    li {
        list-style-type: none;
    }
    .action-container {
        flex: 1;
    }
    .action-container > div {
        width: 100%;
        padding: 0 15px;
    }
    .action-container > div > button {
        width: 100%;
    }

    .arrow {
        margin-left: 10px;
    }
    .action-link {
        flex: 1;
    }
    .link {
        border-bottom: 1px solid #000;
        width: fit-content;
        margin-bottom: 5px;
        margin-left: 15px;
        cursor: pointer;
        flex: 1;
    }
    .action {
        margin-top: 23px;
        display: flex;
    }
    p {
        font-size: 12px;
        margin-top: 15px;
    }
    .upload-info {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .drop-zone .invalid-feedback {
        text-align: center;
    }
    .drop-zone-form {
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 0 15px;
    }
    .drop-zone-form .control-label {
        padding-left: 0;
    }
    .drop-zone {
        width: 100%;
        height: 87%;
        border: 1px solid #A38970;
        position: relative;
    }
    .form-content {
        display: flex;
    }
    .left-side {
        flex: 1;
    }
    .right-side {
        flex: 1;
    }
    .file-uploader {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen and (max-width: 840px) {
        .form-content {
            flex-direction: column;
        }

        .drop-zone {
            height: 250px;
        }

         .action-container {
            flex: none;
            width: 100%;
        }
    }
</style>