<template>
  <div class="footer">
      <div class="footer-container">
            <div class="top">
                <div class="left">
                    <img class="header-item" @click="navigate()" src="../assets/BookCharity.svg">
                </div>
                <div class="center">
                    <p>Электронная почта: <span>info@bookcharity.ru</span></p>
                    <p>Пункт выдачи: <span>г. Рязань, ул. Есенина 9, 5-й этаж</span></p>
                </div>
                <div class="right">
                    <div class="sotial">
                        <a href="https://www.instagram.com/maggvlk" target="_blank" rel="noopener noreferrer">
                            <i class="fab fa-lg fa-instagram">
                            </i>
                        </a>
                        <a href="https://vk.com/maaggie" target="_blank" rel="noopener noreferrer">
                            <i class="fab fa-lg fa-vk"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="left">
                    <div class="copyright">© 2020-2021 BookCharity | Политика конфиденциальности</div>
                </div>
                <div class="right">
                    <div>Разработка: <span class="developer"><a href="https://www.instagram.com/vladislavvolkov" target="_blank" rel="noopener noreferrer">@vladislavvolkov</a></span></div>
                </div>
            </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.left img {
    cursor: pointer;
    width: 210px;
}

.center span, .sotial, a {
    color: #A38970;
}

.center p {
    margin-bottom: 0;
}

.developer a {
    color: #3D464D;
}

.center, .left {
    text-align: left;
}

.sotial {
    display: flex;
    justify-content: flex-end;
}

.sotial a {
    border: 1px solid;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    text-decoration: none;
}

.bottom, .center {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    color: #3D464D;
}

.footer-container {
    width: 80%;
    margin: 0 auto;
    border-top: 1px solid #A38970;
    margin-top: 100px;
    padding-top: 45px;
    padding-bottom: 45px;
}

.top, .bottom {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 840px) {
    .top {
        flex-direction: column;
    }

    .top > div {
        margin-bottom: 20px;
    }

    .top .left, .top .center {
        text-align: center;
    }
    .sotial {
        justify-content: center;
    }

    .bottom {
        justify-content: center;
        flex-direction: column-reverse;
    }

    .bottom .left {
        text-align: center;
    }
}
</style>