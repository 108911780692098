<template>
    <div class="menu">
        <ul class="nav">
            <li class="nav-item" v-for="(item ,index) in items" :key="index">
                <a class="nav-link" @click="item.handler">{{item.text}}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        items: Array
    }
}
</script>

<style scoped>
.menu {
    margin-top: 0;
    margin-left: 0;
}
.nav a {
    color: #2c3e50;
}
li.nav-item {
    background-color: #F8F6F4;
    border-bottom: 2px solid #fff;
    cursor: pointer;
    width: 100%;
}
li.nav-item:hover {
    opacity: 0.7;
}
</style>