<template>
  <div class="menu-container">
      <img class="header-item" @click="navigate()" src="../assets/BookCharity.svg">
      <div class="menu">
        <router-link to="/main">Главная</router-link>
        <!-- <router-link to="/books">Книги</router-link> -->
        <!-- <router-link to="/sponsors">Добавить книги</router-link> -->
        <!-- <router-link to="/shop">Магазин</router-link> -->
        <router-link to="/contacts">Контакты</router-link>
      </div>

        <div class="dropdown">
            <button @click="openMenu" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Меню сайта
            </button>
            <div class="dropdown-menu" @click="openMenu" :style="{ display: dispValue }" aria-labelledby="dropdownMenuButton">
                <router-link class="dropdown-item" to="/main">Главная</router-link>
                <!-- <router-link class="dropdown-item" to="/books">Книги</router-link> -->
                <!-- <router-link class="dropdown-item" to="/sponsors">Добавить книги</router-link> -->
                <!-- <router-link class="dropdown-item" to="/shop">Магазин</router-link> -->
                <router-link class="dropdown-item" to="/contacts">Контакты</router-link>
            </div>
        </div>
  </div>
</template>

<script>
// ЗАКРЫТА
import { useRouter } from "vue-router";
import { ref } from "vue";
export default {
    setup() {
        const router = new useRouter();
        const navigate = () => {
            router.push("/main");
        };
        const dispValue = ref("none");
        const openMenu = () => {
            dispValue.value = dispValue.value === "none" ? "block" : "none";
        }
        return {
            navigate,
            dispValue,
            openMenu
        }
    }

}
</script>

<style scoped>
.menu-container {
    display: flex;
    align-items: center;
    flex: 2;
}
.menu a {
    margin: 5px;
    color: #A38970;
    cursor: pointer;
}

.menu {
    margin-top: 15px;
    margin-left: 15px;
    visibility: visible;
}

.menu-container img {
    cursor: pointer;
    width: 330px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dropdown {
    visibility: hidden;
    display: none;
}

@media only screen and (max-width: 1115px) {
    .header-container img {
        width: 230px;
    }
}

@media only screen and (max-width: 840px) {
    .header-container img {
        width: 90%;
    }

    .menu-container {
        flex-direction: column;
        margin-top: 15px;
        margin-bottom: 30px;
    }

    .dropdown {
        visibility: visible;
        display: block;
    }

    .menu {
        visibility: hidden;
    }
    .dropdown-menu {
        text-align: center;
        left: 50%;
        width: 215px;
        margin-left: -107px;
    }

    .dropdown-item {
        padding: .50rem 2.5rem;
    }
}

</style>