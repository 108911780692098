<template>
    <div v-if="books.length || products.length" class="cart-container">
        <h5 v-if="books.length">Книги</h5>
        <table v-if="books.length" class="table table-striped">
            <tr class="list-header">
                <td class="title">Название</td>
                <td class="title">Город</td>
                <td class="title">Цена</td>
                <td>&nbsp;</td>
            </tr>
            <tr v-for="(book, id) in books" :key="id">
                <td>{{book.name}}</td>
                <td>{{book.city}}</td>
                <td>{{book.price}} руб.</td>
                <td><i class="fas fa-trash-alt remove" @click="removeFromList(book.id)"></i></td>
            </tr>
        </table>
        <br>
        <h5 v-if="products.length">Дополнительные товары</h5>
        <table v-if="products.length" class="table table-striped">
            <tr class="list-header">
                <td class="title">Название</td>
                <td class="title">Количество</td>
                <td class="title">Цена</td>
                <td>&nbsp;</td>
            </tr>
            <tr v-for="(product, id) in products" :key="id">
                <td>{{product.name}}</td>
                <td>
                    <div>
                        <i class="fas fa-minus-circle counter" @click="minus(product)"></i>
                        <div class="count-result">{{product.count}}</div>
                        <i class="fas fa-plus-circle counter" @click="plus(product)"></i>
                    </div>
                </td>
                <td>{{product.price}} руб.</td>
                <!-- <td><i class="fas fa-trash-alt remove" @click="removeFromList(book.id)"></i></td> -->
            </tr>
        </table>
        <div>Итого: {{sum}} руб.</div>
        <BaseButton class="order-button" @click="navigateToOrder()">
            <span>Оформить заказ</span>
        </BaseButton>
    </div>
    <div v-else class="empty-cart">
        <span>В корзине пусто ;(</span>
    </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router";
import BaseButton from "../BaseButton";
export default {
    setup(props, { emit }) {
        const store = useStore();
        const router = useRouter();
        const books = computed(() => store.getters.booksCartList);
        const products = computed(() => store.getters.productList);
        const removeFromList = (id) => {
            store.commit("REMOVE_BOOK_FROM_LIST", id);
        };

        const getSum = (calc, item) => {
            return calc + item.price;
        };

        const sum = computed(() => {
            const booksSum = books.value.reduce(getSum, 0);
            const productsSum = products.value.reduce(getSum, 0);
            return booksSum + productsSum ;
        });

        const navigateToOrder = () => {
            emit("onOrder", true);
            router.push("order");
        };

        const minus = (item) => {
            store.commit("REMOVE_PRODUCT_FROM_LIST", { ...item });
        };
        const plus = (item) => {
            store.commit("ADD_PRODUCT_TO_LIST", { ...item });
        };

        return {
            books,
            removeFromList,
            evenRow: "even-row",
            navigateToOrder,
            products,
            sum,
            minus,
            plus
        };
    },
    components: {
        BaseButton
    }
}
</script>

<style>
.book-price {
    max-width: 85px;
}
.count-result {
    min-width: 25px;
    display: inline-block;
    text-align: center;
}
.counter {
    color: #A38970;
    cursor: pointer;
}

table {
    margin: 0 auto;
    width: 95%;
}

.list-header {
    background-color: #F8F6F4;
}

.order-button {
    float: right;
    margin-bottom: 20px;
}

.remove {
    cursor: pointer;
    color: #A38970;
}

.empty-cart {
    text-align: center;
}

</style>