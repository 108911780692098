<template>
  <div class="book" >
        <div class="exlusive-book" v-if="bookData.exclusive">Exclusive</div>
        <div class="author text">{{bookData.author}}</div>
        <div class="image-container" @click="openModal(bookData)">
            <img v-if="bookData.images && bookData.images.length" :src="bookData.images[0]" />
        </div>
        <div class="name text">
            <p>{{bookData.name}}</p>
            <!-- <p v-if="bookData.price" class="price">от {{bookData.price}} руб.</p> -->
        </div>
      <div class="actions">
          <div class="link" @click="openModal(bookData)">Cостояние книги<i class="arrow fas fa-long-arrow-alt-right"></i></div>
          <Button :added="isAddedBook(bookData.id)" :bookData="{...bookData}"></Button>
      </div>
  </div>
  <BookInfo :showModal="visible" @onClose="close()" :book="bookData"></BookInfo>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import Button from "./cart/Button";
import BookInfo from "./ModalBook";
export default {
    setup() {
        const store = useStore();
        const visible = ref(false);
        const addedBook = computed(() => {
            return store.getters.booksCartList;
        });

        const isAddedBook = (id) => {
            return addedBook.value.some(b => b.id === id);
        }

        const close = () => {
            visible.value = false;
        }

        const openModal = () => {
            visible.value = true;
        }

        const addToCart = (bookData) => {
            store.commit("ADD_BOOK_TO_LIST", { ...bookData });
        }

        return {
            openModal,
            close,
            visible,
            addToCart,
            isAddedBook
        };
    },
    components: {
        Button,
        BookInfo
    },
    props: {
        bookData: Object,
        bookId: Number
    }

}
</script>

<style scoped>
.actions {
    display: flex;
    justify-content: space-between;
}
.exlusive-book {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #F4A45C;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
}
.arrow {
    margin-left: 10px;
}

.book:first-child:hover  {
  transition: 1s;
  transform: scale(1.02);
  opacity: 0.8;
}

.book:not(:hover) {
  transition: 1s;
  transform: scale(1);
  opacity: 1;
}

.book-sold {
    opacity: 0.6;
}

.book-sold:first-child:hover{
    transition: 0s;
    transform: scale(1);
    opacity: 0.6;
}

.book-sold:not(:hover) {
  transition: 0s;
  transform: scale(1);
  opacity: 0.6;
}

.book-sold img {
  cursor: auto;
}

.price {
    text-align: center;
}

.text {
    font-family: Montserrat;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    color: #A38970;
    margin-left: 42px;
}
.author {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    width: 80%;
    height: 95px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.image-container {
    width: 160px;
    height: 290px;
    margin: 0px auto 20px auto;
    overflow: hidden;
}

.name {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    min-height: 78px;
}

.link {
    border-bottom: 1px solid #000;
    width: fit-content;
    margin-bottom: 5px;
    cursor: pointer;
}

.sold {
   color: #A38970;
   margin-bottom: 5px;
}

.link:hover {
    color: #A38970;
    border-bottom: 1px solid #A38970;
}

img {
    width: 160px;
    cursor: pointer;
}

.book {
    position: relative;
    background-color: #F8F6F4;
    width: 410px;
    padding: 20px;
    margin: 0 5px;
    margin-bottom: 15px;
}
@media only screen and (max-width: 840px) {

.book {
    margin: 0 auto;
}
.link {
    margin-top: 0;
}
.text {
    margin-left: 0;
}
}

@media only screen and (max-width: 500px) {
    .book {
        width: 90%;
    }
    .name {
        min-height: 40px;
    }
    img {
        width: 60%;
    }
    .author {
        min-height: auto;
    }

    .image-container {
        margin: 42px auto;
        width: 100%;
        height: 100%;
    }

    .actions {
        flex-direction: column;
        justify-content: center;
    }

    .actions button {
        margin-top: 20px;
        padding: 0 15px;
    }

    .text {
        margin-left: 0;
    }
    .link {
        margin: auto;
        margin-bottom: 25px;
    }
}

</style>