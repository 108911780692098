<template>
    <Popup :showX="false" class="popup-price" :opened="opened" @onClose="onClose">
        <template #title><h4>Укажите цену за книгу</h4></template>
        <div class="price-container">
            <Input class="price-input" v-model="priceValue" type="number" min=50 v-model:isValid="isValid"  />
            <div class="invalid-message" v-if="!isValid">Минимальная цена {{minPrice}} руб.</div>
            <div class="price-button">
                <BaseButton @click="onSetValue('setPrice')">Добавить</BaseButton>
                <BaseButton @click="onSetValue('close')">Отменить</BaseButton>
            </div>
        </div>
    </Popup>
    <BaseButton :class="[{disabled: added}]" @click="addToCart()">
      <span v-if="!added">В корзину</span>
      <span v-else>В корзине</span>
    </BaseButton>
</template>

<script>
import { useStore } from "vuex";
import BaseButton from "../BaseButton";
import Input from "../text-input/Simple";
import Popup from "../modal/Popup";
import { ref } from "vue";
export default {
    props: {
        added: {
            type: Boolean
        },
        bookData: {
            type: Object
        }
    },
    setup(props) {
        const exlusive = ref(props.bookData?.price);
        const store = useStore();
        const opened = ref(false);
        const minPrice =  exlusive.value ? parseInt(exlusive.value) : 50;
        const priceValue = ref(0);
        const isValid = ref(true);
        const addToCart = () => {
            opened.value = true;
            // store.commit("ADD_BOOK_TO_LIST", { ...props.bookData });
        }

        const onClose = () => {
            priceValue.value = 0;
            opened.value = false;
        };

        const onSetValue = (type) => {
            if(type === "setPrice") {
                if(priceValue.value < minPrice) {
                    isValid.value = false;
                } else {
                    store.commit("ADD_BOOK_TO_LIST", { ...props.bookData, price: parseInt(priceValue.value) });
                    opened.value = false;
                    priceValue.value = 0;
                }
            } else if(type === "close") {
                opened.value = false;
                priceValue.value = 0;
            }
        };

        return {
            addToCart,
            opened,
            priceValue,
            onSetValue,
            minPrice,
            onClose,
            isValid
        };
    },
    components: {
        BaseButton,
        Input,
        Popup
    }
}
</script>

<style>
.invalid-message {
    color: #dc3545;
}
.price-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    text-align: center;
    flex-direction: column;
}
.price-input {
    max-width: 200px;
    margin: 0 auto;
}
.price-button {
    margin-top: 20px;
}
.price-button button {
    margin: 0 5px;
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>