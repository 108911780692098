<template>
     <Description class="slider-item partner-img" image="stephenknig.png">
        <template #text>
            <div class="desc-date">Партнеры распродажи</div>
            <h2 class="desc-title">Стивен Книг</h2>
            <p class="desc-main">Литературный подкаст Стивен КНИГ - это околокнижные беседы для любителей копнуть поглубже и под большим увеличительным стеклом рассмотреть различные художественные тексты.</p>
            <div class="actions">
                <button type="button" class="btn btn-secondary slider-button">
                    <a target="_blank" href="https://stephenknig.ru/special_2">Слушать совместный эпизод</a>
                </button>
            </div>
        </template>
    </Description>
</template>

<script>
import Description from "../Description";
import { useRouter } from "vue-router";
export default {
    components: {
        Description
    },
    setup() {
        const router = new useRouter();
        const showExclusive = () => {
            router.push({ path: "/books", query: { exlusive: true }});
        };
        return {
            showExclusive
        };
    }
}
</script>

<style scoped>
.slider-button {
    background-color: #F1EDE9 !important;
    color: #2c3e50;
}
.slider-button a {
    color: #2c3e50;
}

.slider-button:hover a {
    color: #fff;
}

a:hover {
    text-decoration: none;
    color: #fff;
}
</style>