<template>
<div class="doggif">
    <img src="../assets/doggie_for_bookcharity.gif" />
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
@media only screen and (max-width: 840px) {
    img {
        width: 100%
    }
}
</style>

