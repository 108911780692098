import { createStore } from 'vuex'
import book from'./modules/book';
import cart from'./modules/cart';
import auth from'./modules/auth';
import user from'./modules/user';
import orders from'./modules/orders';
export const store = createStore({
  modules: {
    book,
    cart,
    auth,
    user,
    orders
  }
})