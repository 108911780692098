<template>
  <Card size="28" class="login-card" v-if="!user">
    <template #title :class="{ disabled: loading }">Войдите чтобы продолжить</template>
    <Loader class="loader" v-if="loading" />
    <div class="login-container" :class="{ disabled: loading }">
      <form class="form-horizontal" @submit.prevent="onSubmit">
            <div class="form-group">
                <label class="col-sm-12 control-label" for="login">Ваш email</label>
                <div class="col-sm-12">
                    <Email v-model="authData.email" v-model:isValid="isValidEmail" />
                    <div class="invalid-feedback">
                        Введите логин
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-12 control-label" for="password">Введите пароль</label>
                <div class="col-sm-12">
                    <Password v-model="authData.password" v-model:isValid="isValidEPassword" />
                    <div class="invalid-feedback">
                        Введите пароль
                    </div>
                </div>
            </div>
            <div class="invalid-feedback" :style="{ display: error ? 'block' : 'none' }">
              Ошибка входа. Проверьте email и пароль.
            </div>
            <div class="auth-actions">
              <BaseButton type="submit" class="btn btn-default login-btn">Войти в личный кабинет</BaseButton>
              <div class="additional">
                <div>Нет личного кабинета? <span><router-link to="/registration">Зарегистрироваться</router-link></span></div>
                <!-- <div>Забыли пароль? <span><router-link to="/restore">Сбросить парль</router-link></span></div> -->
              </div>
            </div>
        </form>
    </div>
  </Card>
  <div v-else>
        <h3>Вы уже авторизованны)</h3>
        <Doggie />
  </div>
</template>

<script>
import BaseButton from "../BaseButton";
import Card from "../../layout/Card";
import Password from "../../components/text-input/Password";
import Email from "../../components/text-input/Email";
import Loader from "../../components/Loader";
import Doggie from "../../components/Dog"
import { reactive, ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    BaseButton,
    Loader,
    Card,
    Password,
    Doggie,
    Email
  },
  setup() {
    const store = useStore();
    const authData = reactive({
      email: "",
      password: ""
    });
    const isValidEmail = ref(true);
    const isValidEPassword = ref(true);
    const submited = ref(false);
    const loading = ref(false);
    const user = computed(() => store.getters.getUserId);
    const error = computed(() => store.getters.getError);
    const onSubmit = () => {
      if(!submited.value) {
        isValidEmail.value = authData.email === "" ? false : true;
        isValidEPassword.value = authData.password === "" ? false : true;
      }

      submited.value = true;

      if(isValidEmail.value && isValidEPassword.value) {
        loading.value = true;
        store.dispatch('signInUser', {
          ...authData
        }).finally(() => {
          loading.value = false;
        });
      }
    };
    return {
      error,
      user,
      loading,
      authData,
      onSubmit,
      isValidEmail,
      isValidEPassword
    };
  }
}
</script>

<style>
.login-card {
    position: relative;
}
.additional {
    text-align: center;
    margin-top: 23px;
}

.additional a {
  color: #A38970;
}

.login-btn {
    width: 100%;
}
.auth-actions {
  padding: 0 15px;
}

.card-title {
  padding: 0 15px;
}

.form-group {
  text-align: left;
  margin-bottom: 30px;
}
</style>