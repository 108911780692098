<template>
  <div class="funder-books">
    <table v-if="books.length" class="table table-striped">
        <tr>
            <td class="title">Название</td>
            <td class="title">Автор</td>
            <td class="title">Обложка</td>
            <td class="title">Язык</td>
            <td class="title">Купили</td>
        </tr>
        <tr>
        </tr>
        <tr v-for="(book, id) in books" :key="id">
            <td>{{book.name}}</td>
            <td>{{book.author}}</td>
            <td>{{book.cover}}</td>
            <td>{{book.launguage}}</td>
            <td :class="{bought: book.bought}">{{book.bought ? "Да" : "Нет"}}</td>
        </tr>
    </table>
    <div v-if="!books.length" class="empty-table">Вы не добавляли книги</div>
    <!-- <BaseButton class="add-book-btn" @click="openForm">Добавить книгу</BaseButton> -->
  </div>
</template>

<script>
//ЗАКРЫТА
// import BaseButton from "../components/BaseButton";
import { computed } from "vue";
import { useStore } from "vuex"
import { useRouter } from "vue-router";
export default {
  components: {
    // BaseButton
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const uid = computed(() => store.getters.getUserId);
    const books = computed(() => store.getters.getUserBooks);
    store.dispatch("bumpUserBooks");

    const openForm = () => {
      router.push({ path: `/account/${uid.value}/new-book`});
    };

    return {
      openForm,
      close,
      books
    }
  }
}
</script>

<style scoped>
h4 {
  color: #2B2B2B;
}
.new-book-popup {
  width: 50%;
}
.table, .empty-table {
  text-align: center;
}
.funder-books {
  text-align: right;
}
.bought {
  background-color: #d4edda;
}

@media only screen and (max-width: 840px) {
    .funder-books {
        margin-top: 20px;
        overflow: scroll;
    }
    .add-book-btn {
        margin-top: 20px;
        width: 100%;
    }
}

</style>