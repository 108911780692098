<template>
    <div class="description-container">
        <div class="description-text">
            <slot name="text"></slot>
        </div>
        <div class="image-container">
            <div class="image" v-bind:style="{ 'background-image': `url(${require('../assets/' + image)})` }"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        image: String
    }
}
</script>

<style scoped>
.image-container {
    flex: 2;
}
.image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.description-container {
    background-color: #F1EDE9;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.description-text {
    flex: 1;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 80px;
    text-align: left;
}

@media only screen and (max-width: 840px) {
.description-container {
    flex-direction: column-reverse;
    justify-content: center;
}
.description-text {
    padding-left: 0;
    padding-top: 80px;
    padding-right: 0;
    text-align: center;
    margin: 0 auto;
    width: 80%;
}
.image-container {
    display: none;
}
}
@media only screen and (max-width: 770px) {
    .description-text {
        padding-top: 60px;
    }
}

</style>