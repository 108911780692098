<template>
    <div class="cart" @click="open()">
        <Badge :value="count" />
        <div class="shop-bag"></div>
        <Popup :opened="visible" @onClose="close()">
            <template #title><h4>Корзина</h4></template>
            <Modal @onOrder="close()" />
        </Popup>
    </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import Badge from "../Badge";
import Popup from "../modal/Popup";
import Modal from "./Modal";
export default {
    setup() {
        const store = useStore();
        const count = computed(() => store.getters.countCart);
        const visible = ref(false);
        const openedValue = computed(() => store.getters.cartPopup);

        const close = () => {
            visible.value = false;
        }

        const open = () => {
            visible.value = !visible.value;
        }

        return {
            count,
            close,
            open,
            openedValue,
            visible
        };
    },
    components: {
        Badge,
        Popup,
        Modal
    }
}
</script>

<style>
.shop-bag {
    width: 30px;
    height: 30px;
    background-image: url("../../assets/cart.svg");
    background-repeat: no-repeat;
}
.base-colour {
    color: #A38970;
}
.cart {
    margin-top: 15px;
    cursor: pointer;
    position: relative;
}
.cart:hover {
    opacity: 0.8;
}
@media only screen and (max-width: 840px) {
    .cart {
        position: absolute;
        right: 20px;
    }
}
</style>