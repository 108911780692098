<template>
    <Slider />
    <Info />
    <Result :soldBooks="sold" :books="freeBooks" :count="count" />
    <CharityRules />
</template>

<script>
// ЗАКРЫТА
import CharityRules from "../components/CharityRule";
import Info from "../components/Info";
// import Support from "../components/home-page/Support.vue";
import Slider from "../components/slider/Slider.vue";
import Result from "../components/Result.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, ref } from "vue";
export default {
    components: {
        Info,
        CharityRules,
        Slider,
        // Support,
        Result
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const visible = ref(false);
        const sold = computed(() => {
            return store.getters.soldBooks;
        });
        const freeBooks = computed(() => {
            return store.getters.freeBooks;
        });
        const count = computed(() => {
            return store.getters.price;
        });
        const goShop = () => {
            router.push("/books");
        };

        const showSupport = () => {
            visible.value = true;
        };

        const close = () => {
            visible.value = false;
        };

        return {
            count,
            visible,
            freeBooks,
            close,
            goShop,
            sold,
            showSupport
        };
    }
}
</script>

<style scoped>

</style>