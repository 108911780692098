<template>
    <div>
        <div class="page-header">
            <div class="title-container">
                <h2 id="books" class="page-title">Каталог книг</h2>
                <BookFilter :exclusive="exclusive"></BookFilter>
            </div>
            <Toolbar class="books-toolbar"></ToolBar>
        </div>
    <div class="list-book">
        <div v-for="(book, index) in books" :key="index">
            <Book :bookData="book" :bookId="book.id + 1"/>
        </div>
    </div>
    <Spinner v-if="!books.length"/>
  </div>
</template>

<script>
import Book from "./Book";
import Spinner from "../components/Spinner";
import Toolbar from "./ToolBar";
import BookFilter from "./BookFilter";
export default {
    props: {
        books: Array,
        exclusive: Boolean
    },
    setup() {

    },
    components: {
        Toolbar,
        Spinner,
        Book,
        BookFilter
    }

}
</script>

<style scoped>
.title-container {
    display: flex;
    align-items: center;
    min-width: 335px;
}
.list-book {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 600px;
    margin: 0 auto;
}

.page-header {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    padding-top: 150px;
    padding-bottom: 50px;
}

.books-toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    flex: 3;
}

.page-title {
    text-align: center;
    margin-left: 0;
    margin-top: 0;
}

#books {
    min-width: 295px;
}

.list-book div{
    margin-bottom: 20px;
}

@media only screen and (max-width: 840px) {
    .title-container {
        justify-content: center;
    }
    .books-toolbar {
        flex-direction: column;
        margin-top: 20px;
    }
    .page-header {
        flex-direction: column;
        padding-top: 50px;
    }
    .list-book {
        flex-direction: column;
        flex-wrap: nowrap;
        min-width: 280px;
        width: 100%;
    }
}
</style>