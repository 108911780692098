<template>
    <Header @onSearch="onSearch($event)" />
    <router-view></router-view>
    <Footer></Footer>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import firebase from "firebase/app";
import { useStore } from "vuex";
export default {
    components: {
        Header,
        Footer
    },
    setup() {
        const store = useStore();
        store.dispatch("fetchUserInfo");
        firebase.auth().onAuthStateChanged(user => {
            if (user){
                store.commit("SET_USER_ID", user.uid);
                store.dispatch("setUserData");
        }});
    }
}
</script>

<style>

</style>