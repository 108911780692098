<template>
  <Popup :opened="showModal" @onClose="onClose()">
    <div class="modal-body">
      <div class="title text">{{book.author}}</div>
      <div class="name text">{{book.name}}</div>
      <div class="image-container">
        <Gallery :book="book"></Gallery>
      </div>
      <div class="additions">
        <div>Язык: {{book.launguage}}</div>
        <div>Жанр: {{book.genre}}</div>
        <div>Город: {{book.city}}</div>
        <div>Обложка: {{book.cover}}</div>
      </div>
      <!-- <Button class="button-cart" :added="isAddedBook(book.id)" :bookData="{...book}"></Button> -->
      <!-- <button type="button" class="btn btn-secondary" @click="onClick">Написать @maggvlk</button> -->
    </div>
  </Popup>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Popup from "./modal/Popup";
// import Button from "./cart/Button";
import Gallery from "./gallery/Gallery";

export default {
    props: {
      showModal: {
        type:  Boolean,
        default: false
      },
      book: Object
    },
    setup(props, { emit }) {
      const store = useStore();
      const visible = ref(false);
      const onClose = () => {
        emit("onClose", false);
      }
      const addedBook = computed(() => {
          return store.getters.booksCartList;
      });

      const isAddedBook = (id) => {
          return addedBook.value.some(b => b.id === id);
      }
      return {
        visible,
        onClose,
        isAddedBook
      }
    },
    components: {
      Popup,
      // Button,
      Gallery
    }
}
</script>

<style scoped>
.button-cart {
  margin-top: 15px;
}
.modal-body {
  text-align: center;
}

.additions div{
  margin-top: 5px;
}

.additions {
    width: 280px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 25px;
}

img {
  width: 280px;
  margin-top: 25px;
}

.image-container {
  min-height: 373px;
  text-align: center;
  margin-top: 25px;
}

.text {
  font-family: Montserrat;
  text-align: center;
  letter-spacing: 0px;
  font-weight: 500;
  font-style: normal;
}

.title {
  font-size: 28px;
  line-height: 36px;
}

.name {
  font-size: 18px;
  line-height: 23px;
}

@media only screen and (max-width: 840px) {
    .modal-content {
      width: 100%;
      height: 100%;
    }
    .button-cart {
      width: 100%;
    }
}

@media only screen and (max-height: 800px) {
    .modal-content {
      height: 100%;
    }
    .modal-body {
      max-height: 95%;
      overflow-y: auto;
    }

    .additions {
        width: 280px;
        margin: 0 auto;
        padding-top: 20px;
    }
}
</style>