<template>
  <div class="slider-container">
      <transition name="component-fade" mode="out-in">
        <component :is="currentComponent" @onActive="active" @onClose="onClose"></component>
      </transition>
      <div class="dots">
          <div
            v-for="(i, id) in views"
            :key="id"
            :class="{'active-dot': currentIndex === id }"
            @click="setCurrentComponent(id, true)">
          </div>
      </div>
  </div>
</template>

<script>
import { markRaw, ref, onUnmounted, onMounted } from 'vue';
import Support from '../home-page/Support.vue';
// import Exlusive from '../home-page/Exlusive.vue';
import Partners from "../home-page/Partners.vue";
import Button from '../cart/Button.vue';
export default {
 components: {
    Support,
    // Exlusive,
    Partners,
    Button
 },
 props: {
    image: {
        type: String,
        default: () => {
            return ''
        }
    }

 },
 setup() {
     const views = [
         Support,
        //  Exlusive,
         Partners
     ];

     const currentComponent = ref(markRaw(Support));
     const currentIndex = ref(0);
     const activeClass = "active-dot";
     const viewsLength = views.length;
     const active = () => {
         stopAnimaion();
     };
     const onClose = () => {
         startAnimation();
     };
     let timer;
     const setCurrentComponent = (index, isDot) => {
        if(currentIndex.value !== index) {
            currentIndex.value = index;
        }
        currentComponent.value = markRaw(views[index]);
        if(isDot) {
           stopAnimaion();
           startAnimation();
        }
    };

    const startAnimation = () => {
        timer = setInterval(() => {
            if(currentIndex.value !== viewsLength -1) {
                currentIndex.value++;
            } else {
                currentIndex.value = 0;
            }

            setCurrentComponent(currentIndex.value);
        }, 5000);
    };

    const stopAnimaion = () => {
        if(timer) {
            clearInterval(timer);
            timer = null;
         }
    };

     onMounted(() => {
        startAnimation();
     });
     onUnmounted(() => {
         stopAnimaion();
     });
     return {
         views,
         active,
         activeClass,
         currentComponent,
         currentIndex,
         setCurrentComponent,
         onClose
     }
 }
}
</script>

<style>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}
.slider-container {
    position: relative;
    height: 460px;
}
.slider-item {
    height: 100%;
}
.dots {
    position: absolute;
    z-index: 1999;
    bottom: 15px;
    left: 50%;
    margin-left: -46px;
}
.active-dot {
    background-color: #A38970!important;
    border: 1px solid #fff;
}
.dots div {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 3px;
    cursor: pointer;
}
</style>