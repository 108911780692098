<template>
    <div v-if="book && book.images" id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item" :class="{ active: index === activeImage }" v-for="(image, index) in book.images" :key="index">
                <div class="image-value" :style="{'backgroundImage': 'url(' + image + ')'}"></div>
            </div>
        </div>
        <div class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" @click="changeActive('prev')">
            <span class="arrow-img"><i class="fas fa-2x fa-arrow-circle-left"></i></span>
        </div>
        <div class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" @click="changeActive('next')">
            <span class="arrow-img"><i class="fas fa-2x fa-arrow-circle-right"></i></span>
        </div>
    </div>
</template>

<script>
import {computed, ref} from "vue";
export default {
    props: {
        book: Object
    },
    setup(props) {

        const activeImage = ref(0);
        const bookLength = computed(() => {
             if(props.book && props.book.images) {
                 return props.book.images.length;
             }

             return 0;
        });

        const changeActive = (val) => {
            if(!bookLength.value) {
                return;
            }

            if(val === "prev" && activeImage.value !== 0) {
                activeImage.value = activeImage.value - 1;
            }

            if(val === "next" && activeImage.value !== bookLength.value - 1) {
                activeImage.value = activeImage.value + 1;
            }
        };

        return {
            changeActive,
            activeImage
        };
    }
}
</script>

<style>
.carousel-item {
    width: 320px;
    height: 420px;
    margin: auto;
}

.arrow-img {
   color: #A38970;
   cursor: pointer;
}

.image-value {
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
}
</style>