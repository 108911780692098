<template>
  <input class="form-check-input" v-model="checkbox" id="checkbox" :class="{ 'is-invalid': isInvalid || !isValid }"  type="checkbox">
</template>

<script>
import { watch, ref } from "vue";
export default {
  props: {
      modelValue: String,
      isRequired: {
        type: Boolean,
        default: () => {
          return true;
        }
      },
      isValid: {
          type: Boolean,
          default: () => {
              return true;
          }
      }
  },
  emits: ['update:modelValue', 'update:isValid'],
  setup(props, { emit }) {
    const checkbox = ref(props.modelValue || false);
    const isInvalid = ref(false);
    watch(checkbox, (value) => {
      if(props.isRequired) {
        isInvalid.value = !checkbox.value;
      }
      emit('update:modelValue', value);
      emit("update:isValid", !isInvalid.value);
    });
    return {
      isInvalid,
      checkbox
    };
  }
}
</script>

<style>

</style>