<template>
  <div class="loader-container">
    <div class="lds-dual-ring"></div>
    <div class="loading-text">{{text}}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    }
  }
}
</script>

<style>
.loader-container {
  position: absolute;
  z-index: 1001;
  left: 0;
  right: 0;
  margin-left: auto;
  top: 50%;
  margin-right: auto;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  color: #F4A45C;
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #F4A45C;
  border-color: #F4A45C transparent #F4A45C transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>