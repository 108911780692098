<template>
    <select id="cover" @change="updateValue($event)" class="custom-select form-control" aria-label="Default select example">
        <option v-for="(item, index) in items" :value="item ? item : ''" :key="index">{{item ? item : "Показать все"}}</option>
    </select>
</template>

<script>
export default {
    props: {
        items: Array,
        checkedItem: String
    },
    emits: ['update:checkedItem'],
    setup(props, { emit }) {
        const updateValue = (e) => {
            emit('update:checkedItem', e.target.value.name ? e.target.value.value : e.target.value);
        }

        return {
            updateValue
        };
    }

}
</script>

<style>

</style>