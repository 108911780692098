import { hasProduct, getProductsCount } from './utils';

export default {
    state: {
        booksCartList: [],
        productList: []
    },

    mutations: {
        ADD_BOOK_TO_LIST(state, book) {
            state.booksCartList.push(book);
        },
        REMOVE_BOOK_FROM_LIST(state, bookId) {
            state.booksCartList = state.booksCartList.filter((i) => i.id !== bookId);
        },
        CLEAN_BOOK_LIST(state) {
            state.booksCartList = [];
        },
        ADD_PRODUCT_TO_LIST(state, product) {
            const products = state.productList;
            const index = hasProduct(products, product);
            if(index !== -1 || index === 0) {
                const price = product.defaultPrice;
                products[index].count++;
                products[index].price += price;
            } else {
                products.push({ ...product, count: 1, defaultPrice: product.price });
            }
        },
        REMOVE_PRODUCT_FROM_LIST(state, product) {
            const products = state.productList;
            const index = hasProduct(products, product);
            if(index !== -1 && products[index].count !== 1) {
                const price = product.defaultPrice;
                products[index].count--;
                products[index].price -= price;
            } else {
                state.productList = products.filter((el, id) => id !== index);
            }
        },
        CLEAN_PRODUCT_LIST(state) {
            state.productList = [];
        },
    },

    // actions: {
    //     re({ commit, state }) {

    //     }
    // },

    getters: {
        booksCartList: state => state.booksCartList,
        productList: state => state.productList,
        countCart: state => {
            return state.booksCartList.length + getProductsCount(state.productList)
        }
    },
}
