<template>
    <div class="support-container">
        <Description class="slider-item" image="description-image.jpg">
            <template #text>
                <div class="desc-date">РАСПРОДАЖА ЗАКОНЧЕНА</div>
                <h2 class="desc-title">Благотоворительная распродажа книг</h2>
                <p class="desc-main">Вы покупаете книгу по цене, которую выбираете сами, + делаете доброе дело, помогая бездомным животным!</p>
                <div class="actions">
                    <button type="button" class="btn btn-secondary slider-button" @click="showSupport">Поддержать проект</button>
                </div>
            </template>
        </Description>
        <Donates :visible="visible" @close="close" />
    </div>
</template>

<script>
import Description from "../Description";
import Donates from "./Donates.vue";
import { useRouter } from "vue-router";
import { date } from '../../common/constants';
// import { useStore } from "vuex";
import { ref } from "vue";

export default {
    name: 'support',
    components: {
        Description,
        Donates
    },
    setup(props, { emit }) {
        const router = useRouter();
        const visible = ref(false);
        const goShop = () => {
            router.push("/books");
        };

        const showSupport = () => {
            visible.value = true;
            emit("onActive", true);
        };

        const close = () => {
            visible.value = false;
            emit("onClose", true);
        };

        return {
            visible,
            close,
            goShop,
            showSupport,
            date
        };
    }
}
</script>

<style scoped>
.support-container {
    height: 100%;
}
.support-content {
    padding-left: 10px;
}

.support-content ul {
    list-style: circle;
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
    background-color: #A38970 !important;
    color: #fff;
}

.slider-button {
    background-color: #F1EDE9 !important;
    color: #2c3e50;
}

.actions button:first-child {
    margin-right: 15px;
}

@media only screen and (max-width: 840px) {
    .btn {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 80px;
    }
    .actions button:first-child {
        margin-right: 0;
        margin-bottom: 0;
    }
    .actions {
        margin-bottom: 30px;
    }
}


@media only screen and (max-width: 770px) {
    .btn {
        margin-bottom: 60px;
    }
}


</style>