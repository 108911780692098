<template>
    <div class="acc-container">
        <div class="user" @click="openAccount">
        </div>
        <div ref="popup" class="filter-content" v-if="visible">
            <Menu v-if="user" :items="items" />
            <Menu v-else :items="loginItems" />
        </div>
    </div>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Menu from "../common/Menu";
export default {
    components: {
        Menu
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const visible = ref(false);
        const popup = ref(null);
        const skipClose = ref(false);
        const user = computed(() => {
            return store.getters.getUserId
        });

        const loginItems = [{
            text: "Войти",
            handler: () => {
                router.push({ path: `/login`});
                visible.value = false;
            }
        }];

        const items = [{
            text: "Личный кабинет",
            handler: () => {
                router.push({ path: `/account/${user.value}/info`});
                visible.value = false;
            }
        }, {
            text: "Выход",
            handler: () => {
                store.dispatch("signOutAction").then(() => {
                    router.push({ path: `/`});
                    visible.value = false;
                })
                .catch();
            }
        }];

        const clickOutside = (e) => {
            if(skipClose.value) {
                skipClose.value = false;
                return;
            }
            if(popup.value && (popup.value === e.target || !popup.value.contains(e.target))) {
                visible.value = false;
            }
        }

        onMounted(() => {
            window.addEventListener("click", clickOutside);
        });
        onUnmounted(() => {
            window.removeEventListener("click", clickOutside);
        });

        const openAccount = () => {
            visible.value = !visible.value;
            skipClose.value = true;
        };

        return {
            user,
            openAccount,
            items,
            loginItems,
            visible,
            popup
        };
    }
}
</script>

<style>
.base-colour {
    color: #A38970;
}
.user {
    margin-top: 15px;
    margin-right: 35px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-image: url("../../assets/account.svg");
}
.user:hover {
    opacity: 0.8;
}
.filter-content {
  transform: translate(-43%, 5px);
  display: block;
  position: absolute;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  overflow-y: auto;
  -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
  box-shadow: 0 3px 9px rgba(0,0,0,.5);
  z-index: 1;
}
@media only screen and (max-width: 840px) {
    .acc-container {
        position: absolute;
        right: 40px;
        top: 2px
    }
}
</style>