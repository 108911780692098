<template>
  <input type="password" v-model="password" :class="{ 'is-invalid': isInvalidPassword || !isValid }" class="form-control" id="password">
</template>

<script>
import { watch, ref } from "vue";
export default {
  props: {
      modelValue: String,
      isValid: {
          type: Boolean,
          default: () => {
              return true;
          }
      }
  },
  emits: ['update:modelValue', 'update:isValid'],
  setup(props, { emit }) {
    const password = ref("");
    const isInvalidPassword = ref(false);
    watch(password, (value) => {
      isInvalidPassword.value = value === "" || value.length < 6 ? true : false;
      emit('update:modelValue', value);
      emit("update:isValid", !isInvalidPassword.value);
    });
    return {
      isInvalidPassword,
      password
    };
  }
}
</script>

<style>

</style>