<template>
    <Description class="contacts-desc" image="books.jpg">
        <template #text>
            <div class="desc-date">{{date}}</div>
            <h2 class="desc-title">Как с нами связаться</h2>
            <p class="desc-main books-main-desc">Пишите нам в социальных сетях или на электронную почту. Также ниже Вы найдете адрес пункта выдачи книг.</p>
        </template>
    </Description>
    <div class="contacts-info">
        <div class="contacts">
            <h2>Наши контакты</h2>
            <div class="contacts-list">
                <div class="contact-item" v-for="(item, index) in contacts" :key="index">
                    {{item.text}}: <span>{{item.value}}</span>
                </div>
                <div class="contact-item">
                    Если вы не сможете привезти книгу в пункт выдачи, пожалуйста, свяжитесь с <span><a href="https://www.instagram.com/maggvlk" target="_blank" rel="noopener noreferrer">@maggvlk</a></span> или <span><a href="https://www.instagram.com/vladislavvolkov" target="_blank" rel="noopener noreferrer">@vladislavvolkov</a></span>.
                </div>
                <div class="contact-item">
                    Автор идеи: <span><a href="https://www.instagram.com/maggvlk" target="_blank" rel="noopener noreferrer">@maggvlk</a></span>
                </div>
                <div class="contact-item">
                    Разработчик: <span><a href="https://www.instagram.com/vladislavvolkov" target="_blank" rel="noopener noreferrer">@vladislavvolkov</a></span>
                </div>
            </div>
        </div>
        <div class="contacts-map">
            <div id="yamap" class="yamap"></div>
        </div>
    </div>
</template>

<script>
import Description from "../components/Description"
import { onMounted } from "vue";
import { date } from '../common/constants';
export default {
    components: {
        Description
    },
    setup() {
        const coords = [54.627760, 39.767629];
        const contacts = [{
            text: "Электронная почта",
            value: "info@bookcharity.ru"
        }, {
            text: "Пункт выдачи",
            value: "г. Рязань, ул. Есенина 9, 5-й этаж"
        }];
        onMounted(() => {
        /* eslint-disable */
            ymaps.ready(function () {
                const myMap = new ymaps.Map('yamap', {
                        center: coords,
                        zoom: 17
                });

                const placemark = new ymaps.Placemark(coords, {
                    balloonContent: 'GlobalHouse'
                });

                myMap.geoObjects.add(placemark);
            })
        })
        return {
            coords,
            contacts,
            date
        };
    }

}
</script>

<style>
.description-container.contacts-desc {
    margin-bottom: 130px;
}
.contacts-desc p {
    margin-bottom: 120px;
}
.contacts-list {
    text-align: left;
    padding-bottom: 100px;
}
.contact-item {
    margin-bottom: 30px;
}

.contact-item span {
    color: #A38970;
}

.contact-item a {
    color: #A38970;
    cursor: pointer;
}

.contacts-info {
    display: flex;
    width: 80%;
    margin: 0 auto;
}

.contacts {
    flex: 1;
}

.contacts-map {
    flex: 1;
}

#yamap {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 100%;
}

.contacts h2 {
    font-family: Montserrat;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 50px;
}

@media only screen and (max-width: 900px) {
    .contacts-info {
        flex-direction: column;
    }

    #yamap {
        height: 500px;
    }
}
</style>