<template>
  <div class="card">
      <div class="card-body">
        <h5 class="card-title"><slot name="title" /></h5>
        <div class="card-text">
            <div class="content"><slot /></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        title: String,
        size: Number
    }
}
</script>

<style>
.card {
    margin: 0 auto;
    border: none;
    max-width: 450px;
}

.card-title {
    margin-bottom: 25px;
}

</style>