<template>
  <div id="header">
    <div class="header-container">
        <Menu />
        <Account />
        <Cart />
    </div>
  </div>
</template>

<script>
import Menu from "./Menu";
import Cart from "./cart/Cart";
import Account from "./account/User";
export default {
    components: {
        Account,
        Menu,
        Cart
    },
    methods: {
        onSearch(e) {
            this.$emit("on-search", e);
        }
    }

}
</script>

<style>
    #header {
        background-color: #fff;
        width: 100%;
        height: 15%;
    }

    .header-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
        width: 80%;
        min-width: 600px;
        align-items: center;
    }

    .header-item:nth-child(2) {
        margin-top: 16px;
        /* margin-left: 20px; */
        flex: 1;
    }

@media only screen and (max-width: 840px) {
    .header-container {
        flex-direction: column;
        width: 100%;
        min-width: 280px;
    }
    .header-item:nth-child(2) {
        margin: 16px 0 20px 0;
    }

    .header-item:nth-child(2) {
        width: 90%;
    }
}

@media only screen and (max-width: 320px) {
    .header {
        padding: 0 10px;
    }
}
</style>