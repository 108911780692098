<template>
    <teleport to="body">
        <div class="popup" v-if="opened" ref="popup">
            <div class="popup-title" :style="{ justifyContent: showX ? 'space-between' : 'center' }">
                <slot name="title">
                    <div></div>
                </slot>
                <i v-if="showX" class="custom-icon fas fa-times fa-lg close-button" @click="close()"></i>
            </div>
            <div class="popup-content">
                <slot></slot>
            </div>
        </div>
    </teleport>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
export default {
    props: {
        opened: {
            type: Boolean,
            default() {
                return false
            }
        },
        showX: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
    setup(props, { emit }) {
        const popup = ref(null);
        const skipClose = ref(true);
        const isButton = ref(false);
        const close = () => {
            skipClose.value = true;
            isButton.value = true;
            emit("onClose", props.opened);
        }

        const clickOutside = (e) => {
            if(!popup.value) {
                return;
            }
            if(isButton.value) {
                isButton.value = false;
                return;
            }

            if(skipClose.value) {
                skipClose.value = false;
                return;
            }
            if(popup.value === e.target || !popup.value.contains(e.target)) {
                skipClose.value = true;
                emit("onClose",false);
            }
        }

        onMounted(() => {
            window.addEventListener("click", clickOutside);
        });
        onUnmounted(() => {
            window.removeEventListener("click", clickOutside);
        });

        return {
            close,
            popup
        }
    }
}
</script>

<style>

.popup {
    z-index: 9999;
    width: 760px;
    position: fixed;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%,-50%);
    -moz-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background-color: #fff;
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
}

.popup-content {
    padding: 20px;
}

.popup-title {
    display: flex;
    padding: 15px 30px;
}

.close-button {
    cursor: pointer;
    color: #A38970;
}

@media only screen and (max-height: 800px) {
    .popup {
      height: 100%;
      width: 100%;
    }
    .popup-content {
      max-height: 95%;
      overflow-y: auto;
    }
}

@media only screen and (max-height: 800px) {
    .popup {
      height: 100%;
      width: 100%;
    }
    .popup-content {
      max-height: 95%;
      overflow-y: auto;
    }
}

@media only screen and (max-width: 800px) {
    .popup {
      width: 100%;
    }
}

</style>