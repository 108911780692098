import { createApp } from 'vue'
import { router } from './router';
import { store } from './store';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import App from './App.vue'
const firebaseConfig = {
    apiKey: "AIzaSyC3lxeOy0E8CLYAWd0JtHFU5epEyaXkb-8",
    authDomain: "bookcharity-e107a.firebaseapp.com",
    projectId: "bookcharity-e107a",
    storageBucket: "bookcharity-e107a.appspot.com",
    messagingSenderId: "490687966179",
    appId: "1:490687966179:web:d6c09780a924b8b0750ed6"
};

firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const app = createApp(App);

app.use(router);
app.use(store);

app.mount('#app');
