<template>
  <Layout />
</template>

<script>
import Layout from './layout/Layout'
import { useStore } from "vuex";
export default {
  name: 'App',
  components: {
    Layout
  },
  setup() {
    const store = useStore();
    store.dispatch("getAllBooks");
  }
}
</script>

<style>
.desc-date {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: left;

}

.desc-title {
    font-family: Montserrat;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0.02em;
    text-align: left;

}

.desc-main {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;

}
label {
  color: #2B2B2B
}
.form-control {
  color: #A38970;
  border: 1px solid #A38970;
}

.page-title {
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0px;
    text-align: left;
    margin-left: 10%;
    margin-top: 60px;
}

.form-control:focus {
  border: 1px solid #A38970;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1, h2, h3, h4, p, div, span {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
}
.logo {
  width: 200px;

}
@media only screen and (max-width: 840px) {
.desc-date {
    text-align: center;

}

.desc-title {
    text-align: center;
    font-size: 24px;
    line-height: 35px;
}

.desc-main {
    text-align: center;

}
.page-title {
    text-align: center;
    margin-left: 0;
}

}

@media only screen and (max-width: 280px) {
    .desc-title {
        text-align: center;
        font-size: 20px;
        line-height: 35px;
    }

    .desc-main {
        font-size: 16px;
    }
}
</style>
