import firebase from "firebase/app";

export default {
    actions: {
        async addOrder(_, payload) {
            const date = new Date();
            const bprice = payload.books.reduce((acc, i) => {
                acc + i
            }, 0);
            const ppricr = payload.products.reduce((acc, i) => {
                acc + i
            }, 0);
            firebase.firestore().collection("orders").doc(payload.orderId).set({
                ...payload,
                id: payload.orderId,
                city: payload.customer.city,
                books: payload.books,
                products: payload.products,
                name: payload.customer.name,
                surname: payload.customer.surname,
                price: bprice + ppricr,
                email: payload.customer.email,
                phone: payload.customer.phone,
                userNotifited: false,
                processed: false,
                date: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
            }).then(() => {
                console.log("Book added");
            }).catch((err) => {
                console.log(err);
            });
        }
    }
}
