<template>
    <button type="button" class="btn btn-secondary">
        <slot></slot>
    </button>
</template>

<script>
export default {

}
</script>

<style>
.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
  background-color: #A38970 !important;
  color: #fff;
  border-color: #A38970 !important;
}
.btn-secondary:focus {
    box-shadow: none !important;
}
.btn {
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  height: 52px;
}
</style>