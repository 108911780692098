<template>
  <Popup :opened="visible" @onClose="close()">
        <template #title><h4>Поддержи проект</h4></template>
        <div class="support-content">
            <b>На что нам нужны средства:</b>
            <ul>
                <li>Оплата хостинга;</li>
                <li>Оплата домена;</li>
                <li>Продвижение проекта, реклама;</li>
                <li>Дальнейшая разработка технических процессов;</li>
                <li>Печать закладок и других приятностей;</li>
            </ul>
            <b>Как помочь проекту?</b>
            <ul>
                <li>С помощью формы перевода денег;</li>
                <li>Обратиться к организаторам распродажи для получения реквизитов;</li>
            </ul>
            <div>
                <iframe src="https://yoomoney.ru/quickpay/shop-widget?writer=seller&targets=%D0%9F%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%B0%D1%82%D1%8C%20%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82&targets-hint=&default-sum=500&button-text=11&hint=&successURL=&quickpay=shop&account=4100116885229164" width="423" height="222" frameborder="0" allowtransparency="true" scrolling="no"></iframe>
            </div>
        </div>
    </Popup>
</template>

<script>
import Popup from "../modal/Popup.vue";
export default {
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            }
        },
    },
    components: {
        Popup
    },
    setup(props, { emit }) {
        const close = () => {
            emit('close', true);
        };
        return {
            close
        };
    }
}
</script>

<style>

</style>