<template>
  <h2>Личный кабинет</h2>
  <Loader v-if="!uid && !userData" />
  <div class="account" v-if="userData">
    <Menu :items="items" />
    <div class="content">
      <router-view :userData="userData"></router-view>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import Menu from "../components/common/Menu";
import Loader from "../components/Loader";
export default {
  components: {
    Loader,
    Menu
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const uid = computed(() => store.getters.getUserId)
    const page = ref("info");
    const userData = computed(() => {
      return store.getters.getUserData;
    });
    const items = [{
      text: "Мои данные",
      handler: () => {
        router.push({ path: `/account/${uid.value}/info`});
      }
    }, {
      text: "Мои книги",
      handler: () => {
        router.push({ path: `/account/${uid.value}/books`});
      }
    }];
    const changePage = (newPage) => {
      page.value = newPage;
    };
    return {
      changePage,
      uid,
      userData,
      items,
      page
    };
  }
}
</script>

<style scoped>
h2 {
  margin-bottom: 35px;
}
.account {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
}
.menu {
    flex: 1;
}
.menu .nav-item {
  margin-bottom: 10px;
}
.nav {
    display: flex;
    flex-direction: column;
}
.content {
  flex: 3;
  margin: 0 15px;
}
.nav a {
    color: #A38970;
}
li.nav-item {
    background-color: #F8F6F4;
    border-bottom: 2px solid #fff;
    cursor: pointer;
}
@media only screen and (max-width: 840px) {
    .account {
        flex-direction: column;
    }
}
</style>